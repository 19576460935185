import React, { useEffect, useRef, useState } from "react";
import MagicScrollButton from "../../components/MagicScrollButton/MagicScrollButton";
import arrow from "../../assets/images/rewards/arrow.png";
import { motion, useScroll, useTransform } from 'framer-motion'
import styles from './index.module.css'

function Rewards() {
    const rewardSection = useRef(null)
    const rewardContainer = useRef(null)
    const [Width, setWidth] = useState(0);

    const { scrollYProgress } = useScroll({
        target: rewardSection
    })
    const scrollPosition = useTransform(scrollYProgress, [0, 1], ["0px", "-" + Width + "px"])


    useEffect(() => {
        const childs = rewardContainer.current.children
        const totalChilds = childs.length;
        const cardsTotalWidth = childs[0].clientWidth * (totalChilds / 2);
        const arrowsTotalWidth = childs[1].clientWidth * (totalChilds / 2);
        const padding = totalChilds * 24;
        setWidth(cardsTotalWidth + arrowsTotalWidth + padding - rewardContainer.current.clientWidth)

    }, []);
    return (
        <>
            <div className="inner-container">

                <section data-aos="fade-left" ref={rewardSection} className="position-relative" style={{ height: '1000vh' }}>
                    <div className={`overflow-hidden position-sticky ${styles.rewardsWrapper}`} >
                        <div className="container mt-5">
                            <h1 className={`${styles.title} text-center`}>
                                <span className="text-primary">Over $100,000 in</span> exclusive Level Up Rewards
                            </h1>
                        </div>
                        <p className="text-center mb-5">
                            if the deposit Bonuses / Leaderboard and Cashback of roobet.com
                            isn't enough for you, here are Level Rewards. <br /> If you signed
                            up under Code "SEBM" you will have access to these{" "}
                            <span className="text-primary text-center">
                                EXCLUSIVE Level Up Rewards
                            </span>
                        </p>
                        
                        <motion.div ref={rewardContainer} style={{ translateX: scrollPosition }} className={`d-flex flex-nowrap ${styles.rewardsContainer}`}>
                            <div className={`my-2`}>
                                <div className={`card d-flex flex-column justify-content-between py-4 p-2 mx-2 ${styles.card}`}>
                                    <p className="text-center fs-2 fw-bold mt-3">Gold 1</p>
                                    <div className="">
                                        <div className="text-primary fw-bolder text-center text-over-shade">
                                            $50
                                        </div>
                                        <div className="text-shade text-center">$50</div>

                                    </div>
                                    <div className="my-2 pt-2 d-flex justify-content-center align-items-center px-2">
                                        <a href="https://roobet.com/?ref=sebm" target="_blank" className="btn btn-primary w-100">
                                            Earn Now
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-4 col-lg-2 my-2 align-self-center">
                                <img className="img-fluid" src={arrow} alt="" />
                                <p className="text-center fs-2 fw-bold">Gold 2</p>
                            </div>
                            <div className={`my-2`}>
                                <div className={`card d-flex flex-column justify-content-between py-4 p-2 mx-2 ${styles.card}`}>
                                    <p className="text-center fs-2 fw-bold mt-3">Gold 2</p>
                                    <div className="">
                                        <div className="text-primary fw-bolder text-center text-over-shade">
                                            $100
                                        </div>
                                        <div className="text-shade text-center">$100</div>

                                    </div>
                                    <div className="my-2 pt-2 d-flex justify-content-center align-items-center px-2">
                                        <a href="https://roobet.com/?ref=sebm" target="_blank" className="btn btn-primary w-100">
                                            Earn Now
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-4 col-lg-2 my-2 align-self-center">
                                <img className="img-fluid" src={arrow} alt="" />
                                <p className="text-center fs-2 fw-bold">Gold 3</p>
                            </div>
                            <div className={`my-2`}>
                                <div className={`card d-flex flex-column justify-content-between py-4 p-2 mx-2 ${styles.card}`}>
                                    <p className="text-center fs-2 fw-bold mt-3">Gold 3</p>
                                    <div className="">
                                        <div className="text-primary fw-bolder text-center text-over-shade">
                                            $150
                                        </div>
                                        <div className="text-shade text-center">$150</div>

                                    </div>
                                    <div className="my-2 pt-2 d-flex justify-content-center align-items-center px-2">
                                        <a href="https://roobet.com/?ref=sebm" target="_blank" className="btn btn-primary w-100">
                                            Earn Now
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-4 col-lg-2 my-2 align-self-center">
                                <img className="img-fluid" src={arrow} alt="" />
                                <p className="text-center fs-2 fw-bold">Gold 4</p>
                            </div>
                            <div className={`my-2`}>
                                <div className={`card d-flex flex-column justify-content-between py-4 p-2 mx-2 ${styles.card}`}>
                                    <p className="text-center fs-2 fw-bold mt-3">Gold 4</p>
                                    <div className="">
                                        <div className="text-primary fw-bolder text-center text-over-shade">
                                            $250
                                        </div>
                                        <div className="text-shade text-center">$250</div>

                                    </div>
                                    <div className="my-2 pt-2 d-flex justify-content-center align-items-center px-2">
                                        <a href="https://roobet.com/?ref=sebm" target="_blank" className="btn btn-primary w-100">
                                            Earn Now
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-4 col-lg-2 my-2 align-self-center">
                                <img className="img-fluid" src={arrow} alt="" />
                                <p className="text-center fs-2 fw-bold">Emerald 1</p>
                            </div>
                            <div className={`my-2`}>
                                <div className={`card d-flex flex-column justify-content-between py-4 p-2 mx-2 ${styles.card}`}>
                                    <p className="text-center fs-2 fw-bold mt-3">Emerald 1</p>
                                    <div className="">
                                        <div className="text-primary fw-bolder text-center text-over-shade">
                                            $500
                                        </div>
                                        <div className="text-shade text-center">$500</div>

                                    </div>
                                    <div className="my-2 pt-2 d-flex justify-content-center align-items-center px-2">
                                        <a href="https://roobet.com/?ref=sebm" target="_blank" className="btn btn-primary w-100">
                                            Earn Now
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-4 col-lg-2 my-2 align-self-center">
                                <img className="img-fluid" src={arrow} alt="" />
                                <p className="text-center fs-2 fw-bold">Emerald 2</p>
                            </div>
                            <div className={`my-2`}>
                                <div className={`card d-flex flex-column justify-content-between py-4 p-2 mx-2 ${styles.card}`}>
                                    <p className="text-center fs-2 fw-bold mt-3">Emerald 2</p>
                                    <div className="">
                                        <div className="text-primary fw-bolder text-center text-over-shade">
                                            $800
                                        </div>
                                        <div className="text-shade text-center">$800</div>

                                    </div>
                                    <div className="my-2 pt-2 d-flex justify-content-center align-items-center px-2">
                                        <a href="https://roobet.com/?ref=sebm" target="_blank" className="btn btn-primary w-100">
                                            Earn Now
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-4 col-lg-2 my-2 align-self-center">
                                <img className="img-fluid" src={arrow} alt="" />
                                <p className="text-center fs-2 fw-bold">Emerald 3</p>
                            </div>
                            <div className={`my-2`}>
                                <div className={`card d-flex flex-column justify-content-between py-4 p-2 mx-2 ${styles.card}`}>
                                    <p className="text-center fs-2 fw-bold mt-3">Emerald 3</p>
                                    <div className="">
                                        <div className="text-primary fw-bolder text-center text-over-shade">
                                            $1,500
                                        </div>
                                        <div className="text-shade text-center">$1,500</div>

                                    </div>
                                    <div className="my-2 pt-2 d-flex justify-content-center align-items-center px-2">
                                        <a href="https://roobet.com/?ref=sebm" target="_blank" className="btn btn-primary w-100">
                                            Earn Now
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-4 col-lg-2 my-2 align-self-center">
                                <img className="img-fluid" src={arrow} alt="" />
                                <p className="text-center fs-2 fw-bold">Ruby 1</p>
                            </div>
                            <div className={`my-2`}>
                                <div className={`card d-flex flex-column justify-content-between py-4 p-2 mx-2 ${styles.card}`}>
                                    <p className="text-center fs-2 fw-bold mt-3">Ruby 1</p>
                                    <div className="">
                                        <div className="text-primary fw-bolder text-center text-over-shade">
                                            $2,500
                                        </div>
                                        <div className="text-shade text-center">$2,500</div>

                                    </div>
                                    <div className="my-2 pt-2 d-flex justify-content-center align-items-center px-2">
                                        <a href="https://roobet.com/?ref=sebm" target="_blank" className="btn btn-primary w-100">
                                            Earn Now
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-4 col-lg-2 my-2 align-self-center">
                                <img className="img-fluid" src={arrow} alt="" />
                                <p className="text-center fs-2 fw-bold">Ruby 2</p>
                            </div>
                            <div className={`my-2`}>
                                <div className={`card d-flex flex-column justify-content-between py-4 p-2 mx-2 ${styles.card}`}>
                                    <p className="text-center fs-2 fw-bold mt-3">Ruby 2</p>
                                    <div className="">
                                        <div className="text-primary fw-bolder text-center text-over-shade">
                                            $4,000
                                        </div>
                                        <div className="text-shade text-center">$4,000</div>

                                    </div>
                                    <div className="my-2 pt-2 d-flex justify-content-center align-items-center px-2">
                                        <a href="https://roobet.com/?ref=sebm" target="_blank" className="btn btn-primary w-100">
                                            Earn Now
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-4 col-lg-2 my-2 align-self-center">
                                <img className="img-fluid" src={arrow} alt="" />
                                <p className="text-center fs-2 fw-bold">Ruby 3</p>
                            </div>
                            <div className={`my-2`}>
                                <div className={`card d-flex flex-column justify-content-between py-4 p-2 mx-2 ${styles.card}`}>
                                    <p className="text-center fs-2 fw-bold mt-3">Ruby 3</p>
                                    <div className="">
                                        <div className="text-primary fw-bolder text-center text-over-shade">
                                            $7,000
                                        </div>
                                        <div className="text-shade text-center">$7,000</div>

                                    </div>
                                    <div className="my-2 pt-2 d-flex justify-content-center align-items-center px-2">
                                        <a href="https://roobet.com/?ref=sebm" target="_blank" className="btn btn-primary w-100">
                                            Earn Now
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-4 col-lg-2 my-2 align-self-center">
                                <img className="img-fluid" src={arrow} alt="" />
                                <p className="text-center fs-2 fw-bold">Diamond 1</p>
                            </div>
                            <div className={`my-2`}>
                                <div className={`card d-flex flex-column justify-content-between py-4 p-2 mx-2 ${styles.card}`}>
                                    <p className="text-center fs-2 fw-bold mt-3">Diamond 1</p>
                                    <div className="">
                                        <div className="text-primary fw-bolder text-center text-over-shade">
                                            $12,000
                                        </div>
                                        <div className="text-shade text-center">$12,000</div>

                                    </div>
                                    <div className="my-2 pt-2 d-flex justify-content-center align-items-center px-2">
                                        <a href="https://roobet.com/?ref=sebm" target="_blank" className="btn btn-primary w-100">
                                            Earn Now
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-4 col-lg-2 my-2 align-self-center">
                                <img className="img-fluid" src={arrow} alt="" />
                                <p className="text-center fs-2 fw-bold">Diamond 2</p>
                            </div>
                            <div className={`my-2`}>
                                <div className={`card d-flex flex-column justify-content-between py-4 p-2 mx-2 ${styles.card}`}>
                                    <p className="text-center fs-2 fw-bold mt-3">Diamond 2</p>
                                    <div className="">
                                        <div className="text-primary fw-bolder text-center text-over-shade">
                                            $20,000
                                        </div>
                                        <div className="text-shade text-center">$20,000</div>

                                    </div>
                                    <div className="my-2 pt-2 d-flex justify-content-center align-items-center px-2">
                                        <a href="https://roobet.com/?ref=sebm" target="_blank" className="btn btn-primary w-100">
                                            Earn Now
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-4 col-lg-2 my-2 align-self-center">
                                <img className="img-fluid" src={arrow} alt="" />
                                <p className="text-center fs-2 fw-bold">Diamond 3</p>
                            </div>
                            <div className={`my-2`}>
                                <div className={`card d-flex flex-column justify-content-between py-4 p-2 mx-2 ${styles.card}`}>
                                    <p className="text-center fs-2 fw-bold mt-3">Diamond 3</p>
                                    <div className="">
                                        <div className="text-primary fw-bolder text-center text-over-shade">
                                            $30,000
                                        </div>
                                        <div className="text-shade text-center">$30,000</div>

                                    </div>
                                    <div className="my-2 pt-2 d-flex justify-content-center align-items-center px-2">
                                        <a href="https://roobet.com/?ref=sebm" target="_blank" className="btn btn-primary w-100">
                                            Earn Now
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-4 col-lg-2 my-2 align-self-center">
                                <img className="img-fluid" src={arrow} alt="" />
                                <p className="text-center fs-2 fw-bold">Champion 1</p>
                            </div>
                            <div className={`my-2`}>
                                <div className={`card d-flex flex-column justify-content-between py-4 p-2 mx-2 ${styles.card}`}>
                                    <p className="text-center fs-2 fw-bold mt-3">Champion 1</p>
                                    <div className="">
                                        <div className="text-primary fw-bolder text-center text-over-shade">
                                            $50,000
                                        </div>
                                        <div className="text-shade text-center">$50,000</div>

                                    </div>
                                    <div className="my-2 pt-2 d-flex justify-content-center align-items-center px-2">
                                        <a href="https://roobet.com/?ref=sebm" target="_blank" className="btn btn-primary w-100">
                                            Earn Now
                                        </a>
                                    </div>
                                </div>
                            </div>
                                                        <div className="col-12 col-md-4 col-lg-2 my-2 align-self-center">
                                <img className="img-fluid" src={arrow} alt="" />
                                <p className="text-center fs-2 fw-bold">Champion 2</p>
                            </div>
                            <div className={`my-2`}>
                                <div className={`card d-flex flex-column justify-content-between py-4 p-2 mx-2 ${styles.card}`}>
                                    <p className="text-center fs-2 fw-bold mt-3">Champion 2</p>
                                    <div className="">
                                        <div className="text-primary fw-bolder text-center text-over-shade">
                                            $75,000
                                        </div>
                                        <div className="text-shade text-center">$75,000</div>

                                    </div>
                                    <div className="my-2 pt-2 d-flex justify-content-center align-items-center px-2">
                                        <a href="https://roobet.com/?ref=sebm" target="_blank" className="btn btn-primary w-100">
                                            Earn Now
                                        </a>
                                    </div>
                                </div>
                            </div>
                                                        <div className="col-12 col-md-4 col-lg-2 my-2 align-self-center">
                                <img className="img-fluid" src={arrow} alt="" />
                                <p className="text-center fs-2 fw-bold">Champion 3</p>
                            </div>
                            <div className={`my-2`}>
                                <div className={`card d-flex flex-column justify-content-between py-4 p-2 mx-2 ${styles.card}`}>
                                    <p className="text-center fs-2 fw-bold mt-3">Champion 3</p>
                                    <div className="">
                                        <div className="text-primary fw-bolder text-center text-over-shade">
                                            $100,000
                                        </div>
                                        <div className="text-shade text-center">$100,000</div>

                                    </div>
                                    <div className="my-2 pt-2 d-flex justify-content-center align-items-center px-2">
                                        <a href="https://roobet.com/?ref=sebm" target="_blank" className="btn btn-primary w-100">
                                            Earn Now
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </motion.div>
                        <div className={`d-flex justify-content-center align-items-center ${styles.scrollButton}`}>
                            <MagicScrollButton />
                        </div>
                        <p className="mt-5 text-center pt-5">
                            <span className="text-primary">Scroll down</span> to reveal Level Up Rewards with a total value of <span className="text-primary ">over $100,000</span>
                        </p>
                        <p className="text-center mb-5">
                       Your current level can be tracked at <a className="rewardslink" target="_blank" href="https://roobet.com/rewards"> roobet/rewards </a>. The exclusive bonuses from SEBM will be paid by DM'ing him on Discord at SEBM1337. <br />
                       The reward for each tier is the total amount you will have received, meaning when Gold 2 says $100 you will be paid $50 ontop of the $50 you got from Gold 1.
                       <br />
                       Each tier is essentially the difference from last tiers reward to your new tier. All Bonuses only available in the first 2 Months afer SignUp.
                         </p>
                    </div>

                </section>
            </div>
        </>
    );
}

export default Rewards;