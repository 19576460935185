import React, { useState, useEffect } from 'react';
import styles from './index.module.css';
import axios from 'axios';
import img1 from '../../assets/images/roobet.png';
import img2 from '../../assets/images/packdraw.png';

const Leaderboard = () => {
    const [currentLeaderboard, setCurrentLeaderboard] = useState('first');
    const [leaderboardData1, setLeaderboardData1] = useState([]);
    const [leaderboardData2, setLeaderboardData2] = useState([]);
    const [remainingTimeFirst, setRemainingTimeFirst] = useState(null);
    const [remainingTimeSecond, setRemainingTimeSecond] = useState(null);

    // Prize values
    const prizeValues1 = [20000, 10000, 6000, 3000, 2500, 2000, 1500, 1000, 800, 700, 600, 500, 400, 300, 200, 100, 100, 100, 100, 100];
    const prizeValues2 = [7500, 3500, 1500, 1000, 600, 400, 200, 100, 100, 100, 100, 100, 100, 100, 100];

    // Leaderboard texts
    const leaderboardTexts = {
        first: {
            heading: '$50,000 Monthly Leaderboard',
            description: 'All players who use my link to roobet.com or Code "SEBM" will be eligible for prizes on this Exclusive Leaderboard. Updates every 30 minutes.'
        },
        second: {
            heading: '$15,000 Packdraw Leaderboard',
            description: 'Compete in the Packdraw leaderboard for a chance to win big prizes! Use my link to packdraw.com or Code "SEBM" to qualify. Updated every 30 minutes.'
        }
    };

    useEffect(() => {
        axios.get('https://api.sebm1337.com/leaderboard')
            .then(response => {
                setLeaderboardData1(response.data);
            })
            .catch(error => {
                console.error('Error fetching first leaderboard data:', error);
            });

        axios.get('https://api.sebm1337.com/leaderboardpackdraw')
            .then(response => {
                setLeaderboardData2(response.data);
            })
            .catch(error => {
                console.error('Error fetching second leaderboard data:', error);
            });

        const calculateEndOfMonthCountdown = () => {
            const today = new Date();
            const endOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0, 23, 59, 59);
            const millisecondsRemaining = endOfMonth.getTime() - today.getTime();
            return Math.floor(millisecondsRemaining / 1000);
        };

        const calculateEndOfSeptemberCountdown = () => {
            const today = new Date();
            const endOfSeptember = new Date(today.getFullYear(), 8, 30, 23, 59, 59);
            const millisecondsRemaining = endOfSeptember.getTime() - today.getTime();
            return Math.floor(millisecondsRemaining / 1000);
        };

        setRemainingTimeFirst(calculateEndOfMonthCountdown());
        setRemainingTimeSecond(calculateEndOfSeptemberCountdown());

        const timer = setInterval(() => {
            setRemainingTimeFirst(prevTimeFirst => prevTimeFirst > 0 ? prevTimeFirst - 1 : 0);
            setRemainingTimeSecond(prevTimeSecond => prevTimeSecond > 0 ? prevTimeSecond - 1 : 0);
        }, 1000);

        return () => clearInterval(timer);
    }, []);

    const addComma = (num) => {
        if (num !== undefined) {
            return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        } else {
            return '';
        }
    };

    const timerWrapperStyle = {
        border: '1px solid #E0980C ',
        borderRadius: '5px',
        fontWeight: 'bold',
        color: '#E0980C ',
        padding: '0.5rem 2.5rem',
        display: 'inline-block',
        backgroundColor: '#262230',
    };

    const formatTime = (seconds) => {
        const days = Math.floor(seconds / (3600 * 24));
        const hours = Math.floor((seconds % (3600 * 24)) / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const remainingSeconds = seconds % 60;
        return `${days}D ${hours}H ${minutes}M ${remainingSeconds}S`;
    };

    const renderLeaderboard = (leaderboardData, isPackdraw, remainingTime) => (
        <>
            <div className="row">
                {isPackdraw ? (
                    <>
                        <div data-aos="fade-down" className="col-md-4 mt-5 pt-5 mb-md-5">
                            <div className={`${styles.card} card my-2`}>
                                <div className="card-header-half">2</div>
                                <h3 className="card-title text-center fw-bold">{leaderboardData[1]?.username}</h3>
                                <p className='fw-bold text-center py-3'>
                                    Wagered:
                                    <br />
                                    <span className='text-outline-primary'>$ {addComma(parseFloat(leaderboardData[1]?.weightedWagered || 0).toFixed(2))}</span>
                                </p>
                                <a href="#" className="btn btn-primary mt-2">Price: ${prizeValues2[1]}</a>
                            </div>
                        </div>
                        <div data-aos="fade-down" className="col-md-4 mt-5 pt-5 mb-md-5">
                            <div className={`${styles.card} card scaled-md my-2`}>
                                <div className="card-header-half">1</div>
                                <h3 className="card-title text-center fw-bold">{leaderboardData[0]?.username}</h3>
                                <p className='fw-bold text-center py-3'>
                                    Wagered:
                                    <br />
                                    <span className='text-outline-primary'>$ {addComma(parseFloat(leaderboardData[0]?.weightedWagered || 0).toFixed(2))}</span>
                                </p>
                                <a href="#" className="btn btn-primary mt-2">Price: ${prizeValues2[0]}</a>
                            </div>
                        </div>
                        <div data-aos="fade-down" className="col-md-4 mt-5 pt-5 mb-md-5">
                            <div className={`${styles.card} card my-2`}>
                                <div className="card-header-half">3</div>
                                <h3 className="card-title text-center fw-bold">{leaderboardData[2]?.username}</h3>
                                <p className='fw-bold text-center py-3'>
                                    Wagered:
                                    <br />
                                    <span className='text-outline-primary'>$ {addComma(parseFloat(leaderboardData[2]?.weightedWagered || 0).toFixed(2))}</span>
                                </p>
                                <a href="#" className="btn btn-primary mt-2">Price: ${prizeValues2[2]}</a>
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        <div data-aos="fade-down" className="col-md-4 mt-5 pt-5 mb-md-5">
                            <div className={`${styles.card} card my-2`}>
                                <div className="card-header-half">2</div>
                                <h3 className="card-title text-center fw-bold">{leaderboardData[1]?.username}</h3>
                                <p className='fw-bold text-center py-3'>
                                    Wagered:
                                    <br />
                                    <span className='text-outline-primary'>$ {addComma(parseFloat(leaderboardData[1]?.weightedWagered || 0).toFixed(2))}</span>
                                </p>
                                <a href="#" className="btn btn-primary mt-2">Price: ${prizeValues1[1]}</a>
                            </div>
                        </div>
                        <div data-aos="fade-down" className="col-md-4 mt-5 pt-5 mb-md-5">
                            <div className={`${styles.card} card scaled-md my-2`}>
                                <div className="card-header-half">1</div>
                                <h3 className="card-title text-center fw-bold">{leaderboardData[0]?.username}</h3>
                                <p className='fw-bold text-center py-3'>
                                    Wagered:
                                    <br />
                                    <span className='text-outline-primary'>$ {addComma(parseFloat(leaderboardData[0]?.weightedWagered || 0).toFixed(2))}</span>
                                </p>
                                <a href="#" className="btn btn-primary mt-2">Price: ${prizeValues1[0]}</a>
                            </div>
                        </div>
                        <div data-aos="fade-down" className="col-md-4 mt-5 pt-5 mb-md-5">
                            <div className={`${styles.card} card my-2`}>
                                <div className="card-header-half">3</div>
                                <h3 className="card-title text-center fw-bold">{leaderboardData[2]?.username}</h3>
                                <p className='fw-bold text-center py-3'>
                                    Wagered:
                                    <br />
                                    <span className='text-outline-primary'>$ {addComma(parseFloat(leaderboardData[2]?.weightedWagered || 0).toFixed(2))}</span>
                                </p>
                                <a href="#" className="btn btn-primary mt-2">Price: ${prizeValues1[2]}</a>
                            </div>
                        </div>
                    </>
                )}
            </div>
            <div className="row">
                <div className="col text-center">
                    {remainingTime !== null && (
                        <div style={timerWrapperStyle}>{formatTime(remainingTime)}</div>
                    )}
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <div className="table-responsive">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Name</th>
                                    <th>Wagered</th>
                                    <th>Price</th>
                                </tr>
                            </thead>
                            <tbody>
                                {leaderboardData.slice(3, isPackdraw ? 15 : 20).map((user, index) => (
                                    <tr key={index} data-aos="fade-right" data-aos-delay={index * 200} data-aos-offset={-index * 200}>
                                        <td><div className='table-index'>{index + 4}</div></td>
                                        <td>{user.username}</td>
                                        <td>$ {addComma(parseFloat(user.weightedWagered || 0).toFixed(2))}</td>
                                        <td className='text-primary'>
                                            ${addComma(
                                                isPackdraw 
                                                ? prizeValues2[index + 3] 
                                                : prizeValues1[index + 3]
                                            )}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    );

    return (
        <div className='inner-container'>
            <div data-aos="fade-in" className="container">
                <h1 className="title text-center">
                    <span className='text-primary'>
                        {currentLeaderboard === 'first' ? '$50,000' : '$15,000'}
                    </span>
                    {currentLeaderboard === 'first' ? ' Monthly Leaderboard' : ' Packdraw Leaderboard'}
                </h1>
                <p className='text-center mx-auto' style={{ maxWidth: '600px' }}>
                    {currentLeaderboard === 'first' 
                        ? 'All players who use my link to roobet.com or Code "SEBM" will be eligible for prizes on this Exclusive Leaderboard. Updates every 30 minutes.'
                        : 'Compete in the Packdraw leaderboard for a chance to win big prizes! Use my link to Packdraw or Code "SEBM" to qualify. Updated every 30 minutes.'
                    }
                </p>
            </div>

            <div className="text-center my-4">
                <img src={img1} alt="Leaderboard 1" onClick={() => setCurrentLeaderboard('first')} style={{ cursor: 'pointer', marginRight: '20px' }} />
                <img src={img2} alt="Leaderboard 2" onClick={() => setCurrentLeaderboard('second')} style={{ cursor: 'pointer' }} />
            </div>

            <div className="container">
                {currentLeaderboard === 'first' ? renderLeaderboard(leaderboardData1, false, remainingTimeFirst) : renderLeaderboard(leaderboardData2, true, remainingTimeSecond)}
            </div>
        </div>
    );
};

export default Leaderboard;
