import React from 'react';
import styles from './index.module.css'
import img from '../../../assets/images/how-to-register/section-4.png'

const SectionFour = () => {
    return (
        <div className="skewed-container">
            <div className="container">
                <div className="row gap-4">
                    <div data-aos="fade-right" className="col-md">
                        <img src={img} className='mw-100' alt="" />
                    </div>
                    <div data-aos="fade-left" className="col-md-4 align-self-center">
                        <h1><span className="title text-primary">Rewards</span> Overview</h1>
                        <p>In the <span className="text-primary">Rewards Tab</span>you can Claim your current Rewards. You can also track your current progress on the Rewards and much more!</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SectionFour;
